import React from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import { graphql } from "gatsby";
import ModuleRender from "@components/modules/moduleRender";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RequestValuation from '@components/modules/Forms/RequestProjections';
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

const RequestProjectionsTemplate = ({
    data
}) => {
    // const pageurl = typeof window !== 'undefined' ? window.location.pathname : page?.Slug
    const { glstrapi: { page } } = data
    const breadcrumbs = [
        { label: page?.Pagename, url: `${page?.Slug}` }
    ]

    return (
        <Layout popularSearch={page?.Popular_Search} bodyClass="noBannerImage list-property-page" headerClass="header-dark">
            <Seo
                title={page?.Metadata?.title ? page.Metadata.title : page.Title}
                description={page?.Metadata?.description ? page.Metadata.description : null}
                // image={page?.Metadata?.image?.url ? page.Metadata.image.url : null}
                image={page?.Metadata?.image?.url_sharp?.childImageSharp ? page.Metadata.image.url_sharp.childImageSharp.resize : null}
            />
            <Container fluid="lg">
                <Row>
                    <Col xs={12} xl={7}>
                        <RequestValuation
                            sectionClass="section-list-property-form"
                            sectionSpacing="py-40 py-md-64 py-lg-80"
                            removeContainer={true}
                            title="Request Projection"
                            text="<p>For a full projection report including potential earnings and related costs for short term rentals please provide below details.</p>"
                            breadcrumbs={breadcrumbs}
                            breadcrumbsClass="section-breadcrumb-dark"
                        />
                    </Col>
                </Row>
            </Container>
            <div className="list-property__image">
                {page?.BannerImage?.[0]?.Image?.url_sharp ?
                    <GatsbyImage
                        image={getImage(page?.BannerImage?.[0]?.Image?.url_sharp)}
                        alt={page?.BannerImage[0]?.Image?.alternativeText ? page?.BannerImage[0]?.Image?.alternativeText : ""}
                        className="list-property__image__image"
                    />
                :
                    <StaticImage
                        src="../images/no-image.png"
                        quality={100}
                        width={800}
                        height={1200}
                        formats={["auto", "webp"]}
                        alt=""
                        className="list-property__image__image"
                    />
                }
            </div>
            {page?.BannerImage &&
                <ModuleRender
                    moduleData={page?.BannerImage}
                />
            }
        </Layout>
    );
}

export default RequestProjectionsTemplate

export const query = graphql`
query requestProjectionsQuery($id: ID!) {
    glstrapi {
        page(id: $id, publicationState: LIVE) {
            Pagename
            Title
            Slug
            HeaderTemplate
            PageTemplate
            Popular_Search
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    id
                    Image {
                        alternativeText
                        url
                        url_sharp {
                            childImageSharp {
                                gatsbyImageData(width: 800, height: 1200, quality: 100)
                            }
                        }
                    }
                }
            }
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }
    }
}
`