import React from "react";
import ModuleSection from '@components/modules/moduleWrapper';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import RequestProjectionsForm from '@components/forms/requestProjections';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumbs from '@components/breadcrumbs';

import { fadeIn } from '@components/utils/animations';



const RequestProjectionsModule = ({ sectionClass, sectionSpacing, removeContainer, title, text, breadcrumbs, breadcrumbsClass }) => {
    return(
        <ModuleSection sectionClass={sectionClass + ' target-element'} sectionSpacing={sectionSpacing} removeContainer={removeContainer} id="listPropertyForm">
            <Row>
                <Col xs={12} variants={fadeIn}>
                    {breadcrumbs.length > 0 &&
                        <div>
                            <Breadcrumbs
                                breadcrumbs={breadcrumbs}
                                breadcrumbsClass={breadcrumbsClass}
                            />
                        </div>
                    }
                    {title &&
                        <ModuleTitle
                            as="h1"
                            title={title}
                            className="module__title mb-16 mb-md-24"
                        />
                    }
                    {text &&
                        <ModuleText
                            text={text}
                            className="module__text mb-32 mb-md-56"
                        />
                    }
                    <RequestProjectionsForm />
                </Col>
            </Row>
        </ModuleSection>
    )
}

export default RequestProjectionsModule